<template>
  <v-container class="grid-list-xl mt-12">
    <v-row justify="center" align="center">
      <v-col cols="12" class="mt-5 text-center">
        <div class="error-text text-center mt-5">500</div>
        <div class="font-3x text-center mb-3">{{$t("page500.serverError")}}</div>
        <div class="fs-16 mb-5 text-center">{{$t("page500.hintMessage")}}</div>
        <v-btn :href="iobots" tile outlined small color="primary" @click="goHome">
          <b>{{$t("page500.homepageButton")}}</b>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
</style>
<script>
import config from "@/config.js";
export default {
  data() {
    return {
      iobots: config.domainName
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
      this.$router.go();
    }
  }
};
</script>
